.technologies {
    padding: ($section-padding * 0.5) 0;

    @include media-breakpoint-up(lg) {
        padding: ($section-padding) 0;
    }

    .content-gallery {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;

            @include media-breakpoint-up(lg) {
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        li {
            margin: 0 10px;

            @include media-breakpoint-up(md) {
                margin: 0 ($grid-gutter * 0.5);
            }

            @include media-breakpoint-up(xl) {
                margin: 0;
            }
        }

        img {
            max-width: 100px;
            max-height: 50px;

            @include media-breakpoint-up(md) {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
