.spotlight {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: all .15s;

    &--active {
        opacity: 1;
    }
}
