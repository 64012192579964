.free-lunch {
    position: relative;
    padding-bottom: $section-padding * 0.5;

    @include media-breakpoint-up(lg) {
        padding-top: $section-padding * 0.5;
    }

    .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-start;
        }
    }

    .content-text {
        position: relative;
        padding: calculateRem(40px) 0;
        color: $secondary-color;

        @include media-breakpoint-up(lg) {
            max-width: calculateRem(730px);
            padding: calculateRem(80px) calculateRem(80px) calculateRem(80px) 0;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -1000em; // align to the left edge of the window
            right: -1000em; // align to the right edge of the window
            height: 100%;
            z-index: -1;
            background-color: #f2f2f2;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                left: -1000em; // align to the left edge of the window
                right: 0;
            }
        }

        p:first-child {
            margin-bottom: calculateRem(20px);
        }

        h2 {
            @extend .h1;
            color: $primary-color;
        }

        blockquote {
            @extend .h2;
            margin-top: $vr;
            margin-bottom: 0;
            color: $primary-color;
            background: url('../images/quote.svg') top left/auto 100% no-repeat;

            @include media-breakpoint-up(lg) {
                padding-left: calculateRem(95px);
            }

            p:first-child {
                margin-bottom: calculateRem(20px);
            }

            p:last-child {
                margin: 0;
                font-family: $font-family-body;
                font-size: 1rem;
                color: $primary-color;
            }

            a {
                color: inherit;
            }
        }
    }

    .ce_app_github_sponsors {
        padding-top: calculateRem(40px);
        color: $secondary-color;

        @include media-breakpoint-up(lg) {
            padding-left: calculateRem(30px);
            max-width: calc(100% - #{calculateRem(730px)});
        }

        h2 {
            color: $primary-color;
            line-height: 1.08;
        }

        p:nth-child(4) {
            display: flex;
            margin-top: calculateRem(40px);
            margin-bottom: calculateRem(40px);

            @include media-breakpoint-up(lg) {
                margin-bottom: calculateRem(60px);
            }

            a {
                @extend %big-link;
            }
        }

        p:nth-child(5) {
            margin-bottom: 5px;
            padding-top: 22px;
            background: url('../images/icon-heart.svg') top left / 14px 12px no-repeat;
        }

        ul:last-child {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin: 0 0 5px 0;
                padding: 0;

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
    }
}
