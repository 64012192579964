.text-content {
    .main .mod_article {
        position: relative;
        padding-top: $section-padding;

        @include media-breakpoint-up(lg) {
            padding-bottom: $section-padding * 0.5;
        }

        h1, h2, h3 {
            margin: calculateRem(25px) 0 calculateRem(15px);
            color: $primary-color;
        }

        .content-text {
            p {
                color: $secondary-color;
                margin-bottom: 1em;
            }

            ul {
                margin: 0 0 1em;
                padding: 0;
                list-style: none;
            }

            li {
                margin: 0;
                color: $secondary-color;
                line-height: 1.9411764706;

                &::before {
                    content: '//';
                    margin-right: 5px;
                    color: $primary-color;
                }
            }
        }
    }

    .footer {
        overflow: hidden;
        background: #f2f2f2;
        margin-top: 40px;
    }
}
