.header {
    background: $secondary-color;
    border-top: 6px solid $primary-color;
    color: #fff;
    font-family: $font-family-headings;
    font-size: calculateRem(11px);
    font-weight: $font-weight-semibold;

    &__inside {
        @extend .container;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: calculateRem(23.5px);
        padding-bottom: calculateRem(23.5px);

        @include media-breakpoint-up(sm) {
            align-items: center;
            flex-direction: row;
        }

        body.page-home & {
            @include media-breakpoint-up(lg) {
                padding-top: calculateRem(13.5px);
                padding-bottom: calculateRem(13.5px);
            }
        }
    }

    &__logo {
        display: block;
        position: absolute;
        left: $grid-gutter * 0.5;
        top: 10px;
        z-index: 1;

        img {
            width: calculateRem(100px);
        }

        body.page-home & {
            @include media-breakpoint-up(lg) {
                top: calc(100% + #{calculateRem(31px)});
            }

            img {
                width: calculateRem(100px);

                @include media-breakpoint-up(lg) {
                    width: calculateRem(200px);
                }
            }
        }
    }

    &__slogan {
        margin-left: calculateRem(120px);

        @include media-breakpoint-up(sm) {
            margin-right: $grid-gutter;
            text-align: left;
        }

        body.page-home & {
            @include media-breakpoint-up(lg) {
                margin-left: 0;
                text-align: left;
            }
        }
    }

    &__language {
        margin-top: calculateRem(10px);
        margin-left: calculateRem(120px);

        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        @include media-breakpoint-up(sm) {
            display: block;
            margin-top: 0;
            margin-left: auto;
        }

        body.page-home & {
            @include media-breakpoint-up(lg) {
                display: block;
                margin-left: auto;
            }
        }

        a {
            @extend %link-underline;
            padding-left: 28px;
            background: url('../images/icon-language-switch.svg') left/18px auto no-repeat;
            color: #fff;
        }
    }
}
