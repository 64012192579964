@font-face {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fira-code.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fira-code.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fira-code.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fira-sans-300.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fira-sans-400.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fira-sans-500.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.github-feed__item-link, .github .content-text:first-child p:last-child a {
  display: inline-block;
  width: 53px;
  height: 11px;
  background: url("../images/link-arrow.svg") no-repeat;
  text-decoration: none;
}
.github-feed__item-link::after, .github .content-text:first-child p:last-child a::after {
  display: none !important;
}

.team .swiper .content-text a, .github-feed__item-description a, .github-feed__item-name a, .construction-pages--related .construction-pages__slider p:last-child a, .boarding-pass__contact-value a, .footer__link, .footer__text p:last-child a, .header__language a, .content-text a {
  display: inline-block;
  text-decoration: none;
}
.team .swiper .content-text a::after, .github-feed__item-description a::after, .github-feed__item-name a::after, .construction-pages--related .construction-pages__slider p:last-child a::after, .boarding-pass__contact-value a::after, .footer__link::after, .footer__text p:last-child a::after, .header__language a::after, .content-text a::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: currentColor;
  transition: width 0.3s;
}
.team .swiper .content-text a:hover, .github-feed__item-description a:hover, .github-feed__item-name a:hover, .construction-pages--related .construction-pages__slider p:last-child a:hover, .boarding-pass__contact-value a:hover, .footer__link:hover, .footer__text p:last-child a:hover, .header__language a:hover, .content-text a:hover, .team .swiper .content-text a.active, .github-feed__item-description a.active, .github-feed__item-name a.active, .construction-pages--related .construction-pages__slider p:last-child a.active, .boarding-pass__contact-value a.active, .active.footer__link, .footer__text p:last-child a.active, .header__language a.active, .content-text a.active {
  color: currentColor;
  text-decoration: none;
}
.team .swiper .content-text a:hover::after, .github-feed__item-description a:hover::after, .github-feed__item-name a:hover::after, .construction-pages--related .construction-pages__slider p:last-child a:hover::after, .boarding-pass__contact-value a:hover::after, .footer__link:hover::after, .footer__text p:last-child a:hover::after, .header__language a:hover::after, .content-text a:hover::after, .team .swiper .content-text a.active::after, .github-feed__item-description a.active::after, .github-feed__item-name a.active::after, .construction-pages--related .construction-pages__slider p:last-child a.active::after, .boarding-pass__contact-value a.active::after, .active.footer__link::after, .footer__text p:last-child a.active::after, .header__language a.active::after, .content-text a.active::after {
  width: 100%;
}

.construction-details a {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
}
.construction-details a::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: width 0.3s;
}

.team .swiper .content-text p:nth-child(2), .github-feed__item-date, .boarding-pass__github-address {
  color: #a13609;
  font-family: "Fira Code", sans-serif;
  font-size: 0.6470588235rem;
}

.open-source .content-text:nth-child(3) p:last-child a, .github-feed__more-link, .free-lunch .ce_app_github_sponsors p:nth-child(4) a, .construction-pages--overview .swiper-container .swiper-slide p:last-child a {
  display: inline-block;
  padding: 1.1764705882rem 2.3529411765rem;
  border: 1px solid #2d3f4b;
  color: #2d3f4b;
  font-family: "Fira Code", sans-serif;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.15s;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(3) p:last-child a, .github-feed__more-link, .free-lunch .ce_app_github_sponsors p:nth-child(4) a, .construction-pages--overview .swiper-container .swiper-slide p:last-child a {
    padding-left: 3.5294117647rem;
    padding-right: 3.5294117647rem;
  }
}
.open-source .content-text:nth-child(3) p:last-child a:hover, .github-feed__more-link:hover, .free-lunch .ce_app_github_sponsors p:nth-child(4) a:hover, .construction-pages--overview .swiper-container .swiper-slide p:last-child a:hover {
  background-color: #2d3f4b;
  color: #fff;
  text-decoration: none;
}
.open-source .content-text:nth-child(3) p:last-child a::after, .github-feed__more-link::after, .free-lunch .ce_app_github_sponsors p:nth-child(4) a::after, .construction-pages--overview .swiper-container .swiper-slide p:last-child a::after {
  display: none !important;
}

.github-feed__item-link, .github .content-text:first-child p:last-child a {
  display: inline-block;
  width: 53px;
  height: 11px;
  background: url("../images/link-arrow.svg") no-repeat;
  text-decoration: none;
}
.github-feed__item-link::after, .github .content-text:first-child p:last-child a::after {
  display: none !important;
}

.team .swiper .content-text a, .github-feed__item-description a, .github-feed__item-name a, .construction-pages--related .construction-pages__slider p:last-child a, .boarding-pass__contact-value a, .footer__link, .footer__text p:last-child a, .header__language a, .content-text a {
  display: inline-block;
  text-decoration: none;
}
.team .swiper .content-text a::after, .github-feed__item-description a::after, .github-feed__item-name a::after, .construction-pages--related .construction-pages__slider p:last-child a::after, .boarding-pass__contact-value a::after, .footer__link::after, .footer__text p:last-child a::after, .header__language a::after, .content-text a::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: currentColor;
  transition: width 0.3s;
}
.team .swiper .content-text a:hover, .github-feed__item-description a:hover, .github-feed__item-name a:hover, .construction-pages--related .construction-pages__slider p:last-child a:hover, .boarding-pass__contact-value a:hover, .footer__link:hover, .footer__text p:last-child a:hover, .header__language a:hover, .content-text a:hover, .team .swiper .content-text a.active, .github-feed__item-description a.active, .github-feed__item-name a.active, .construction-pages--related .construction-pages__slider p:last-child a.active, .boarding-pass__contact-value a.active, .active.footer__link, .footer__text p:last-child a.active, .header__language a.active, .content-text a.active {
  color: currentColor;
  text-decoration: none;
}
.team .swiper .content-text a:hover::after, .github-feed__item-description a:hover::after, .github-feed__item-name a:hover::after, .construction-pages--related .construction-pages__slider p:last-child a:hover::after, .boarding-pass__contact-value a:hover::after, .footer__link:hover::after, .footer__text p:last-child a:hover::after, .header__language a:hover::after, .content-text a:hover::after, .team .swiper .content-text a.active::after, .github-feed__item-description a.active::after, .github-feed__item-name a.active::after, .construction-pages--related .construction-pages__slider p:last-child a.active::after, .boarding-pass__contact-value a.active::after, .active.footer__link::after, .footer__text p:last-child a.active::after, .header__language a.active::after, .content-text a.active::after {
  width: 100%;
}

.construction-details a {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
}
.construction-details a::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: width 0.3s;
}

.team .swiper .content-text p:nth-child(2), .github-feed__item-date, .boarding-pass__github-address {
  color: #a13609;
  font-family: "Fira Code", sans-serif;
  font-size: 0.6470588235rem;
}

.open-source .content-text:nth-child(3) p:last-child a, .github-feed__more-link, .free-lunch .ce_app_github_sponsors p:nth-child(4) a, .construction-pages--overview .swiper-container .swiper-slide p:last-child a {
  display: inline-block;
  padding: 1.1764705882rem 2.3529411765rem;
  border: 1px solid #2d3f4b;
  color: #2d3f4b;
  font-family: "Fira Code", sans-serif;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.15s;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(3) p:last-child a, .github-feed__more-link, .free-lunch .ce_app_github_sponsors p:nth-child(4) a, .construction-pages--overview .swiper-container .swiper-slide p:last-child a {
    padding-left: 3.5294117647rem;
    padding-right: 3.5294117647rem;
  }
}
.open-source .content-text:nth-child(3) p:last-child a:hover, .github-feed__more-link:hover, .free-lunch .ce_app_github_sponsors p:nth-child(4) a:hover, .construction-pages--overview .swiper-container .swiper-slide p:last-child a:hover {
  background-color: #2d3f4b;
  color: #fff;
  text-decoration: none;
}
.open-source .content-text:nth-child(3) p:last-child a::after, .github-feed__more-link::after, .free-lunch .ce_app_github_sponsors p:nth-child(4) a::after, .construction-pages--overview .swiper-container .swiper-slide p:last-child a::after {
  display: none !important;
}

html {
  box-sizing: border-box;
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 17px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, form, figure, blockquote {
  margin: 0;
  padding: 0;
}

img, svg, iframe {
  display: block;
  max-width: 100%;
  border: 0;
}

img, svg {
  height: auto;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

hr {
  height: 1px;
  margin: 3.5em 0;
  border: none;
  background-color: #a13609;
}
hr + .content-text {
  margin-top: -1em;
}

header, footer, main, nav, section, aside, article, figure, figcaption {
  display: block;
}

html, body, form, fieldset, p, div, h1, h2, .team .swiper .content-text p:nth-child(1), h3, h4, h5, h6 {
  -webkit-text-size-adjust: none;
}

body, input, textarea, select {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  color: #a13609;
}

/* Vertical rhythm */
ul, ol, dd,
p, figure,
pre, table, fieldset {
  margin-top: 0;
  margin-bottom: 1.7647058824rem;
}

select, input, textarea {
  font-size: 99%;
}

strong {
  font-weight: 500;
}

a {
  color: #457da7;
  text-decoration: none;
  transition: color 0.15s;
}
a:hover {
  text-decoration: underline;
}

h1, h2, .team .swiper .content-text p:nth-child(1), h3, h4, h5, h6 {
  font-family: "Fira Code", sans-serif;
  font-size: inherit;
  line-height: inherit;
  font-weight: 400;
  margin: 0;
}

h1, .h1, .team h2, .team .swiper .content-text p:nth-child(1), .open-source .content-text:nth-child(3) h2, .open-source .content-text:nth-child(2) h2, .github .content-text:first-child h2, .free-lunch .content-text h2, .construction-pages--related .construction-pages__text h2 {
  margin-bottom: 1.7647058824rem;
  font-size: 1.6470588235rem;
  line-height: 1.08333;
}
@media (min-width: 768px) {
  h1, .h1, .team h2, .team .swiper .content-text p:nth-child(1), .open-source .content-text:nth-child(3) h2, .open-source .content-text:nth-child(2) h2, .github .content-text:first-child h2, .free-lunch .content-text h2, .construction-pages--related .construction-pages__text h2 {
    margin-bottom: 2.3529411765rem;
    font-size: 1.8823529412rem;
  }
}
@media (min-width: 992px) {
  h1, .h1, .team h2, .team .swiper .content-text p:nth-child(1), .open-source .content-text:nth-child(3) h2, .open-source .content-text:nth-child(2) h2, .github .content-text:first-child h2, .free-lunch .content-text h2, .construction-pages--related .construction-pages__text h2 {
    font-size: 2.1176470588rem;
  }
}

h2, .team .swiper .content-text p:nth-child(1), .h2, .free-lunch .content-text blockquote {
  margin-bottom: 2.0588235294rem;
  font-size: 1.4705882353rem;
  line-height: 1.32;
}

figcaption {
  padding: 5px 0;
  font-size: 14px;
  color: #a13609;
  text-align: left;
  letter-spacing: 0.02em;
}

.main {
  overflow: hidden;
}

.overflow {
  overflow: visible;
}

.clear, #clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

.invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.container, .header__inside {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.swiper-slide {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container-sm, .container, .header__inside {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, .header__inside {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, .header__inside {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .header__inside {
    max-width: 1140px;
  }
}
.header {
  background: #2d3f4b;
  border-top: 6px solid #a13609;
  color: #fff;
  font-family: "Fira Code", sans-serif;
  font-size: 0.6470588235rem;
  font-weight: 500;
}
.header__inside {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 1.3823529412rem;
  padding-bottom: 1.3823529412rem;
}
@media (min-width: 576px) {
  .header__inside {
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  body.page-home .header__inside {
    padding-top: 0.7941176471rem;
    padding-bottom: 0.7941176471rem;
  }
}
.header__logo {
  display: block;
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 1;
}
.header__logo img {
  width: 5.8823529412rem;
}
@media (min-width: 992px) {
  body.page-home .header__logo {
    top: calc(100% + 1.8235294118rem);
  }
}
body.page-home .header__logo img {
  width: 5.8823529412rem;
}
@media (min-width: 992px) {
  body.page-home .header__logo img {
    width: 11.7647058824rem;
  }
}
.header__slogan {
  margin-left: 7.0588235294rem;
}
@media (min-width: 576px) {
  .header__slogan {
    margin-right: 30px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  body.page-home .header__slogan {
    margin-left: 0;
    text-align: left;
  }
}
.header__language {
  margin-top: 0.5882352941rem;
  margin-left: 7.0588235294rem;
}
.header__language ul, .header__language li {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 576px) {
  .header__language {
    display: block;
    margin-top: 0;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  body.page-home .header__language {
    display: block;
    margin-left: auto;
  }
}
.header__language a {
  padding-left: 28px;
  background: url("../images/icon-language-switch.svg") left/18px auto no-repeat;
  color: #fff;
}

.footer__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2.5882352941rem;
  padding-bottom: 15vw;
}
@media (min-width: 992px) {
  .footer__content {
    margin-top: 5.1764705882rem;
    padding-bottom: 12.6470588235rem;
  }
}
.footer__content::before {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(32vw - 2px);
  left: 0;
  height: 2.5882352941rem;
  width: 100%;
  background: url("../images/footer-bg-top.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
@media (min-width: 576px) {
  .footer__content::before {
    bottom: 32vw;
  }
}
@media (min-width: 992px) {
  .footer__content::before {
    height: 5.1764705882rem;
    bottom: 20.9411764706rem;
  }
}
.footer__content::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  height: calc(32vw + 10px);
  width: 100%;
  background-color: #bbc9c4;
  background-position: center bottom;
  background-size: 200vw auto;
  background-repeat: no-repeat;
  background-image: url("../images/footer-bg.png");
  background-image: image-set(url("../images/footer-bg.webp") 1x, url("../images/footer-bg.png") 1x);
  pointer-events: none;
}
@media (min-width: 992px) {
  .footer__content::after {
    background-size: 1600px auto;
    height: 21.5294117647rem;
  }
}
.footer__text p:first-child {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
.footer__text p:last-child {
  font-size: 1.1764705882rem;
  margin-bottom: 2.3529411765rem;
  font-weight: 300;
  line-height: 1.32;
}
@media (min-width: 992px) {
  .footer__text p:last-child {
    font-size: 1.4705882353rem;
  }
}
.footer__bar {
  position: relative;
  z-index: 20;
  border-top: 4px solid #a13609;
  background: #2d3f4b;
  color: #fff;
  font-family: "Fira Code", sans-serif;
  font-size: 0.6470588235rem;
  font-weight: 500;
}
.footer__bar .container, .footer__bar .header__inside {
  display: flex;
  align-items: center;
  padding-top: 0.9705882353rem;
  padding-bottom: 0.9705882353rem;
}
.footer__copyright {
  margin-right: auto;
}
.footer__social {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: 1.1764705882rem;
}
.footer__social-link {
  display: block;
  width: 20px;
  height: 16px;
  text-indent: -999em;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  text-decoration: none;
}
.footer__social-link:not(:last-child) {
  margin-right: 0.8823529412rem;
}
.footer__social-link--facebook {
  background-image: url("../images/social--facebook.svg");
}
.footer__social-link--linkedin {
  background-image: url("../images/social--linkedin.svg");
}
.footer__social-link--github {
  background-image: url("../images/social--github.svg");
}
.footer__links {
  display: flex;
  gap: 1.1764705882rem;
}
.footer__link {
  color: #fff;
}

.boarding-pass {
  position: relative;
  max-width: 400px;
  margin-bottom: 3.2352941176rem;
  margin-left: auto;
  margin-right: auto;
  background: url("../images/boarding-pass-mobile.svg") center/100% no-repeat;
  font-size: 8px;
  z-index: 10;
}
@media (min-width: 576px) {
  .boarding-pass {
    max-width: 100%;
    font-size: 10px;
    background: url("../images/boarding-pass.svg") center/100% no-repeat;
  }
}
@media (min-width: 768px) {
  .boarding-pass {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .boarding-pass {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .boarding-pass {
    font-size: 17px;
  }
}
.boarding-pass::before {
  content: "";
  display: block;
  padding-bottom: 108%;
}
@media (min-width: 576px) {
  .boarding-pass::before {
    padding-bottom: 36%;
  }
}
.boarding-pass__codes {
  position: absolute;
  top: 20%;
  left: 5.4%;
  display: flex;
  align-items: center;
  flex: 1;
  color: #2d3f4b;
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
}
@media (min-width: 576px) {
  .boarding-pass__codes {
    top: 27.5%;
    left: 3.6%;
    font-size: 2.12em;
  }
}
.boarding-pass__codes-image {
  margin-left: 0.2em;
  margin-right: 0.2em;
}
@media (min-width: 576px) {
  .boarding-pass__codes-image {
    margin-left: 0.56em;
    margin-right: 0.56em;
  }
}
.boarding-pass__codes-image img {
  width: 0.67em;
}
.boarding-pass__github {
  display: none;
}
.boarding-pass__github:hover {
  text-decoration: none;
}
.boarding-pass__github:hover .boarding-pass__github-address {
  color: #2d3f4b;
}
@media (min-width: 576px) {
  .boarding-pass__github {
    position: absolute;
    top: 27.5%;
    left: 34.23%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  }
}
.boarding-pass__github-barcode {
  margin-right: 0.59em;
}
.boarding-pass__github-barcode img {
  width: 9em;
}
@media (min-width: 576px) {
  .boarding-pass__github-barcode img {
    width: 12em;
  }
}
@media (min-width: 992px) {
  .boarding-pass__github-barcode img {
    width: 15.06em;
  }
}
.boarding-pass__github-icon img {
  width: 1.71em;
}
.boarding-pass__github-address {
  margin-top: 5px;
  width: 100%;
  font-size: 0.6em;
  transition: color 0.15s;
}
@media (min-width: 576px) {
  .boarding-pass__github-address {
    font-size: 1em;
  }
}
.boarding-pass__contact {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 33.5%;
  left: 5.4%;
  width: 89.2%;
}
@media (min-width: 375px) {
  .boarding-pass__contact {
    top: 37.5%;
  }
}
@media (min-width: 576px) {
  .boarding-pass__contact {
    top: auto;
    bottom: 13%;
    left: 4.5%;
    flex-direction: row;
    width: 62.16%;
  }
}
.boarding-pass__contact-column {
  width: 50%;
}
.boarding-pass__contact-column:first-child {
  order: 1;
}
@media (min-width: 576px) {
  .boarding-pass__contact-column:first-child {
    order: 0;
  }
}
.boarding-pass__contact-data:not(:last-child) {
  margin-bottom: 0.4em;
}
@media (min-width: 576px) {
  .boarding-pass__contact-data:not(:last-child) {
    margin-bottom: 0.8em;
  }
}
@media (min-width: 768px) {
  .boarding-pass__contact-data:not(:last-child) {
    margin-bottom: 1.18em;
  }
}
.boarding-pass__contact-label {
  color: #2d3f4b;
  font-size: 14px;
}
@media (min-width: 576px) {
  .boarding-pass__contact-label {
    font-size: 1em;
  }
}
.boarding-pass__contact-value {
  margin-bottom: 10px;
  font-size: 16.47px;
  font-weight: 300;
  line-height: 1.33;
}
@media (min-width: 576px) {
  .boarding-pass__contact-value {
    margin-bottom: 0;
    font-size: 1.17em;
    line-height: 1.32;
  }
}
@media (min-width: 1200px) {
  .boarding-pass__contact-value {
    font-size: 1.47em;
  }
}
.boarding-pass__contact-value a {
  color: #a13609;
}
.boarding-pass__details {
  display: none;
}
@media (min-width: 576px) {
  .boarding-pass__details {
    display: block;
    position: absolute;
    bottom: 13%;
    left: 69.37%;
  }
}
.boarding-pass__details-data:not(:last-child) {
  margin-bottom: 0.4em;
}
@media (min-width: 576px) {
  .boarding-pass__details-data:not(:last-child) {
    margin-bottom: 0.8em;
  }
}
@media (min-width: 768px) {
  .boarding-pass__details-data:not(:last-child) {
    margin-bottom: 1.18em;
  }
}
.boarding-pass__details-label {
  color: #2d3f4b;
  font-size: 0.9em;
}
@media (min-width: 576px) {
  .boarding-pass__details-label {
    font-size: 1em;
  }
}
.boarding-pass__details-value {
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.32;
}
@media (min-width: 576px) {
  .boarding-pass__details-value {
    font-size: 1.17em;
  }
}
@media (min-width: 1200px) {
  .boarding-pass__details-value {
    font-size: 1.47em;
  }
}

.construction-details {
  position: relative;
  padding-top: 4.7058823529rem;
  padding-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .construction-details {
    padding-top: 4.7058823529rem;
    padding-bottom: 4.7058823529rem;
  }
}
.construction-details::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(17.6470588235rem + 2px);
  left: 0;
  z-index: -1;
  height: 2.5882352941rem;
  width: 100%;
  background: url("../images/construction-details-bg-top.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
@media (min-width: 992px) {
  .construction-details::before {
    height: 5.1764705882rem;
  }
}
.construction-details::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: calc(100% - 20.2352941176rem);
  width: 100%;
  background-color: #457da7;
  pointer-events: none;
}
@media (min-width: 992px) {
  .construction-details::after {
    height: calc(100% - 22.8235294118rem);
  }
}
.construction-details > .container, .construction-details > .header__inside {
  display: flex;
  flex-wrap: wrap;
}
.construction-details .content-text:first-child {
  width: 100%;
  margin-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .construction-details .content-text:first-child {
    margin-bottom: 12.9411764706rem;
  }
}
.construction-details .content-text:first-child p:first-child {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
.construction-details .content-text:first-child h1 {
  margin-bottom: 0;
}
.construction-details .construction-details-element {
  width: 100%;
}
@media (min-width: 992px) {
  .construction-details .construction-details-element {
    position: relative;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.construction-details .construction-details-element .project-board {
  margin-top: 0;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .construction-details .construction-details-element .project-board {
    position: absolute;
    top: -9.4117647059rem;
    right: 60px;
    margin-bottom: 0;
  }
}
.construction-details .content-text:last-child {
  position: relative;
  width: 100%;
  color: #fff;
}
@media (min-width: 992px) {
  .construction-details .content-text:last-child {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.construction-details .content-text:last-child::before {
  content: "";
  display: block;
  position: absolute;
  top: -32.9411764706rem;
  right: -25rem;
  width: 42.1176470588rem;
  height: 60rem;
  background: url("../images/construction-details-bg-top-right.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
.construction-details .content-text:last-child::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 57.2352941176rem;
  height: 17.3529411765rem;
  background: url("../images/construction-details-bg-bottom-left.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
@media (min-width: 992px) {
  .construction-details .content-text:last-child::after {
    right: calc(100% + 60px);
  }
}
.construction-details .content-text:last-child h2, .construction-details .team .swiper .content-text:last-child p:nth-child(1), .team .swiper .construction-details .content-text:last-child p:nth-child(1) {
  margin-bottom: 1.1764705882rem;
}
.construction-details .content-text:last-child pre {
  margin-bottom: 0;
  color: #c6e7ff;
  font-family: "Fira Code", sans-serif;
  white-space: normal;
}
@media (min-width: 992px) {
  .construction-details .content-text:last-child pre {
    margin-top: 4.7058823529rem;
  }
}
.construction-pages--overview {
  position: relative;
  min-height: 46.4705882353rem;
  margin-bottom: 2.5882352941rem;
  padding-top: 2.3529411765rem;
  padding-bottom: 2.3529411765rem;
  background: #457da7 url("../images/constructions-slider-bg.svg") center/auto 716px no-repeat;
  color: #fff;
}
@media (min-width: 992px) {
  .construction-pages--overview {
    margin-bottom: 5.1764705882rem;
    padding-top: 4.7058823529rem;
  }
}
.construction-pages--overview::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: -1;
  height: 2.5882352941rem;
  width: 100%;
  background: url("../images/constructions-slider-bg-bottom.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
@media (min-width: 576px) {
  .construction-pages--overview::after {
    top: 100%;
  }
}
@media (min-width: 992px) {
  .construction-pages--overview::after {
    height: 5.1764705882rem;
  }
}
.construction-pages--overview > .container, .construction-pages--overview > .header__inside {
  position: relative;
}
.construction-pages--overview .construction-pages__text:first-child p {
  margin-bottom: 1.1764705882rem;
}
.construction-pages--overview .construction-pages__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .construction-pages--overview .construction-pages__content {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .construction-pages--overview .construction-pages__slider {
    position: relative;
    padding: 0 30px;
    width: calc(100% - 29.4117647059rem);
  }
  .construction-pages--overview .construction-pages__slider::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    left: -11.1764705882rem;
    display: block;
    background-color: rgba(213, 192, 139, 0.2);
    pointer-events: none;
  }
}
.construction-pages--overview .swiper-container .swiper-slide {
  padding-top: 2.3529411765rem;
  padding-bottom: 1.1764705882rem;
  color: #fff;
}
@media (min-width: 992px) {
  .construction-pages--overview .swiper-container .swiper-slide {
    position: relative;
    padding-top: 4.7058823529rem;
  }
  .construction-pages--overview .swiper-container .swiper-slide > * {
    position: relative;
    margin-right: 1.7647058824rem;
    z-index: 1;
  }
}
.construction-pages--overview .swiper-container .swiper-slide h2, .construction-pages--overview .swiper-container .swiper-slide .team .swiper .content-text p:nth-child(1), .team .swiper .content-text .construction-pages--overview .swiper-container .swiper-slide p:nth-child(1) {
  margin-bottom: 1.1764705882rem;
}
.construction-pages--overview .swiper-container .swiper-slide p {
  margin-bottom: 1.7647058824rem;
}
@media (min-width: 992px) {
  .construction-pages--overview .swiper-container .swiper-slide p {
    margin-bottom: 3.5294117647rem;
  }
}
.construction-pages--overview .swiper-container .swiper-slide p:last-child {
  margin-bottom: 0;
}
.construction-pages--overview .swiper-container .swiper-slide p:last-child a {
  border-color: #fff;
  color: #fff;
}
.construction-pages--overview .swiper-container .swiper-slide p:last-child a:hover {
  background-color: #fff;
  color: #457da7;
}
.construction-pages--overview .swiper-button-prev, .construction-pages--overview .swiper-button-next {
  position: absolute;
  top: calc(50% - 8px);
  width: 32px;
  height: 32px;
  padding: 0;
  background: url("../images/icon-arrow-white.svg") center/auto no-repeat;
  border: none;
  cursor: pointer;
  z-index: 10;
}
.construction-pages--overview .swiper-button-prev:disabled, .construction-pages--overview .swiper-button-next:disabled {
  opacity: 0.25;
}
.construction-pages--overview .swiper-button-prev {
  right: calc(100% - 32px);
  transform: scale(-1, 1);
}
@media (min-width: 576px) {
  .construction-pages--overview .swiper-button-prev {
    right: calc(100% + 10px);
  }
}
@media (min-width: 1200px) {
  .construction-pages--overview .swiper-button-prev {
    right: calc(100% + 5.2941176471rem);
  }
}
.construction-pages--overview .swiper-button-next {
  left: calc(100% - 32px);
}
@media (min-width: 576px) {
  .construction-pages--overview .swiper-button-next {
    left: calc(100% + 10px);
  }
}
@media (min-width: 1200px) {
  .construction-pages--overview .swiper-button-next {
    left: calc(100% + 5.2941176471rem);
  }
}
.construction-pages--overview .swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1.7647058824rem;
}
.construction-pages--overview .swiper-pagination button {
  display: block;
  margin: 0 10px;
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: #9ec7e6;
  opacity: 1;
  border-radius: 100%;
  border: none;
  cursor: pointer;
}
.construction-pages--overview .swiper-pagination button.tns-nav-active {
  background-color: #2d3f4b;
}

.construction-pages--related {
  padding-top: 2.3529411765rem;
  padding-bottom: 2.3529411765rem;
  background-color: #f2f2f2;
}
@media (min-width: 992px) {
  .construction-pages--related {
    padding-top: 4.7058823529rem;
    padding-bottom: 4.7058823529rem;
  }
}
.construction-pages--related .construction-pages__text {
  width: 100%;
}
.construction-pages--related .construction-pages__text p:first-child {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
.construction-pages--related .construction-pages__slider .image_container {
  margin-bottom: 0;
}
.construction-pages--related .construction-pages__slider .image_container img {
  width: 100%;
}
.construction-pages--related .construction-pages__slider h3 {
  margin-bottom: 0;
  padding: 1.7647058824rem 1.1764705882rem 1.3529411765rem 1.1764705882rem;
  font-size: 1.4705882353rem;
  background-color: #fff;
}
.construction-pages--related .construction-pages__slider p {
  margin-bottom: 0;
  padding: 0 1.1764705882rem 1.1764705882rem 1.1764705882rem;
  background-color: #fff;
  color: #2d3f4b;
  font-size: 0.8235294118rem;
  line-height: 1.2857142857;
  text-align: justify;
}
.construction-pages--related .construction-pages__slider p:last-child {
  padding-bottom: 2.3529411765rem;
}
.construction-pages--related .construction-pages__slider p:last-child a {
  padding-left: 40px;
  background: url("../images/link-arrow-short.svg") left center/20px auto no-repeat;
  font-family: "Fira Code", sans-serif;
  font-weight: 500;
}
.construction-pages--related .swiper {
  position: relative;
}
.construction-pages--related .swiper .tns-nav {
  display: flex;
  justify-content: center;
  margin-top: 1.7647058824rem;
}
.construction-pages--related .swiper .tns-nav-lock {
  display: none;
}
.construction-pages--related .swiper .tns-nav button {
  display: block;
  margin: 0 10px;
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: #9ec7e6;
  border: none;
  opacity: 1;
  border-radius: 100%;
  cursor: pointer;
}
.construction-pages--related .swiper .tns-nav button.tns-nav-active {
  background-color: #2d3f4b;
}

.mod_article.shop {
  position: relative;
  padding-top: 2.3529411765rem;
  padding-bottom: 2.3529411765rem;
  background: #a13609;
  color: #fff;
}
.mod_article.shop .content-hyperlink {
  display: flex;
  justify-content: center;
}
.mod_article.shop .content-hyperlink a {
  display: block;
  margin-bottom: 0;
  padding-right: 60px;
  font-size: 1.4705882353rem;
  line-height: 35px;
  color: #fff;
  text-decoration: none;
  background: url("../images/shopping-cart.svg") 100% center/contain no-repeat;
  transition: transform 0.3s ease;
}
.mod_article.shop .content-hyperlink a:hover {
  transform: scale(1.2);
}

.text-content .main .mod_article {
  position: relative;
  padding-top: 4.7058823529rem;
}
@media (min-width: 992px) {
  .text-content .main .mod_article {
    padding-bottom: 2.3529411765rem;
  }
}
.text-content .main .mod_article h1, .text-content .main .mod_article h2, .text-content .main .mod_article .team .swiper .content-text p:nth-child(1), .team .swiper .content-text .text-content .main .mod_article p:nth-child(1), .text-content .main .mod_article h3 {
  margin: 1.4705882353rem 0 0.8823529412rem;
  color: #a13609;
}
.text-content .main .mod_article .content-text p {
  color: #2d3f4b;
  margin-bottom: 1em;
}
.text-content .main .mod_article .content-text ul {
  margin: 0 0 1em;
  padding: 0;
  list-style: none;
}
.text-content .main .mod_article .content-text li {
  margin: 0;
  color: #2d3f4b;
  line-height: 1.9411764706;
}
.text-content .main .mod_article .content-text li::before {
  content: "//";
  margin-right: 5px;
  color: #a13609;
}
.text-content .footer {
  overflow: hidden;
  background: #f2f2f2;
  margin-top: 40px;
}

.free-lunch {
  position: relative;
  padding-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .free-lunch {
    padding-top: 2.3529411765rem;
  }
}
@media (min-width: 992px) {
  .free-lunch .container, .free-lunch .header__inside {
    display: flex;
    align-items: flex-start;
  }
}
.free-lunch .content-text {
  position: relative;
  padding: 2.3529411765rem 0;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .free-lunch .content-text {
    max-width: 42.9411764706rem;
    padding: 4.7058823529rem 4.7058823529rem 4.7058823529rem 0;
  }
}
.free-lunch .content-text::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -1000em;
  right: -1000em;
  height: 100%;
  z-index: -1;
  background-color: #f2f2f2;
  pointer-events: none;
}
@media (min-width: 992px) {
  .free-lunch .content-text::before {
    left: -1000em;
    right: 0;
  }
}
.free-lunch .content-text p:first-child {
  margin-bottom: 1.1764705882rem;
}
.free-lunch .content-text h2, .free-lunch .team .swiper .content-text p:nth-child(1), .team .swiper .free-lunch .content-text p:nth-child(1) {
  color: #a13609;
}
.free-lunch .content-text blockquote {
  margin-top: 1.7647058824rem;
  margin-bottom: 0;
  color: #a13609;
  background: url("../images/quote.svg") top left/auto 100% no-repeat;
}
@media (min-width: 992px) {
  .free-lunch .content-text blockquote {
    padding-left: 5.5882352941rem;
  }
}
.free-lunch .content-text blockquote p:first-child {
  margin-bottom: 1.1764705882rem;
}
.free-lunch .content-text blockquote p:last-child {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 1rem;
  color: #a13609;
}
.free-lunch .content-text blockquote a {
  color: inherit;
}
.free-lunch .ce_app_github_sponsors {
  padding-top: 2.3529411765rem;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .free-lunch .ce_app_github_sponsors {
    padding-left: 1.7647058824rem;
    max-width: calc(100% - 42.9411764706rem);
  }
}
.free-lunch .ce_app_github_sponsors h2, .free-lunch .ce_app_github_sponsors .team .swiper .content-text p:nth-child(1), .team .swiper .content-text .free-lunch .ce_app_github_sponsors p:nth-child(1) {
  color: #a13609;
  line-height: 1.08;
}
.free-lunch .ce_app_github_sponsors p:nth-child(4) {
  display: flex;
  margin-top: 2.3529411765rem;
  margin-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .free-lunch .ce_app_github_sponsors p:nth-child(4) {
    margin-bottom: 3.5294117647rem;
  }
}
.free-lunch .ce_app_github_sponsors p:nth-child(5) {
  margin-bottom: 5px;
  padding-top: 22px;
  background: url("../images/icon-heart.svg") top left/14px 12px no-repeat;
}
.free-lunch .ce_app_github_sponsors ul:last-child {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.free-lunch .ce_app_github_sponsors ul:last-child li {
  margin: 0 0 5px 0;
  padding: 0;
}
.free-lunch .ce_app_github_sponsors ul:last-child li:not(:last-child) {
  margin-right: 5px;
}
.free-lunch .ce_app_github_sponsors ul:last-child img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.github {
  padding-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .github {
    padding-bottom: 4.7058823529rem;
  }
}
@media (min-width: 992px) {
  .github .container, .github .header__inside {
    display: flex;
    align-items: flex-start;
    padding-top: 3.5294117647rem;
  }
}
.github .content-text:first-child {
  position: relative;
  padding: 2.3529411765rem 0;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .github .content-text:first-child {
    position: static;
    max-width: 31.7647058824rem;
    margin-top: -3.5294117647rem;
    padding: 2.3529411765rem 1.7647058824rem 1.9411764706rem 1.7647058824rem;
    background-color: rgba(84, 120, 108, 0.4);
  }
}
.github .content-text:first-child::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -1000em;
  right: -1000em;
  height: 100%;
  z-index: -1;
  background-color: rgba(84, 120, 108, 0.4);
  pointer-events: none;
}
@media (min-width: 992px) {
  .github .content-text:first-child::before {
    display: none;
  }
}
.github .content-text:first-child h2, .github .team .swiper .content-text:first-child p:nth-child(1), .team .swiper .github .content-text:first-child p:nth-child(1) {
  color: #a13609;
}
.github .content-text:first-child p:first-child {
  margin-bottom: 1.1764705882rem;
}
.github .content-text:first-child p:last-child {
  margin-bottom: 0;
  margin-top: 1.7647058824rem;
}
.github .content-text:first-child p:last-child a {
  width: auto;
  height: auto;
  padding-left: 4rem;
  background-position: left center;
  color: #a13609;
  transition: all 0.15s;
}
.github .content-text:first-child p:last-child a:hover {
  padding-left: 4.5882352941rem;
  color: inherit;
}
.github-feed {
  position: relative;
  padding: 2.3529411765rem 0;
}
@media (min-width: 992px) {
  .github-feed {
    max-width: calc(100% - 31.7647058824rem);
    min-height: 33.4705882353rem;
    padding: 2.3529411765rem 2.3529411765rem 4.7058823529rem 2.3529411765rem;
  }
}
.github-feed::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -1000em;
  right: -1000em;
  height: 100%;
  z-index: -1;
  background: #faf8ea;
  pointer-events: none;
}
@media (min-width: 992px) {
  .github-feed::before {
    left: -11.1764705882rem;
  }
}
@media (min-width: 992px) {
  .github-feed::after {
    content: "";
    display: block;
    position: absolute;
    left: -9.4117647059rem;
    bottom: 1.7647058824rem;
    width: 8.0588235294rem;
    height: 29.9411764706rem;
    z-index: -1;
    background: url("../images/github-logo-white.svg") center/auto no-repeat;
    pointer-events: none;
  }
}
.github-feed__headline {
  color: #2d3f4b;
}
.github-feed__item {
  margin-bottom: 1.7647058824rem;
}
.github-feed__item:last-child {
  margin-bottom: 2.3529411765rem;
}
.github-feed__item:not(:last-child) {
  padding-bottom: 1.4117647059rem;
  background: url("../images/divider.svg") center bottom/100% auto no-repeat;
}
.github-feed__item-date {
  margin-bottom: 0.5882352941rem;
}
.github-feed__item-name {
  margin-bottom: 0.5882352941rem;
  font-size: 1.1764705882rem;
}
.github-feed__item-description {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
.github-feed__more {
  display: flex;
}
.open-source {
  position: relative;
  padding-top: 4.7058823529rem;
}
@media (min-width: 992px) {
  .open-source {
    padding-bottom: 2.3529411765rem;
  }
}
@media (min-width: 992px) {
  .open-source .container, .open-source .header__inside {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
.open-source .content-text:nth-child(1) {
  width: 100%;
  margin-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(1) {
    margin-bottom: 8.2352941176rem;
  }
}
.open-source .content-text:nth-child(1) p:first-child {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
.open-source .content-text:nth-child(1) h1 {
  margin-bottom: 0;
}
.open-source .content-text:nth-child(2) {
  position: relative;
  padding: 2.3529411765rem 0;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(2) {
    position: static;
    max-width: 31.7647058824rem;
    margin-top: -3.5294117647rem;
    padding: 2.3529411765rem 2.3529411765rem 3.5294117647rem 2.3529411765rem;
    background-color: rgba(84, 120, 108, 0.4);
  }
}
.open-source .content-text:nth-child(2)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -1000em;
  right: -1000em;
  height: 100%;
  z-index: -1;
  background-color: rgba(84, 120, 108, 0.4);
  pointer-events: none;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(2)::before {
    display: none;
  }
}
.open-source .content-text:nth-child(2) h2, .open-source .team .swiper .content-text:nth-child(2) p:nth-child(1), .team .swiper .open-source .content-text:nth-child(2) p:nth-child(1) {
  color: #a13609;
}
.open-source .content-text:nth-child(2) p {
  font-size: 1.4705882353rem;
  font-weight: 300;
  line-height: 1.32;
}
.open-source .content-text:nth-child(2) p:last-child {
  margin-bottom: 0;
}
.open-source .content-text:nth-child(3) {
  position: relative;
  padding: 2.3529411765rem 0;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .open-source .content-text:nth-child(3) {
    padding: 3.5294117647rem 0 3.5294117647rem 3.5294117647rem;
    max-width: calc(100% - 31.7647058824rem);
  }
}
.open-source .content-text:nth-child(3)::before {
  content: "";
  display: block;
  position: absolute;
  left: -11.1764705882rem;
  top: 0;
  right: -1000em;
  height: 100%;
  z-index: -1;
  background: #f1ecd4;
  pointer-events: none;
}
.open-source .content-text:nth-child(3) p:nth-child(2) {
  color: #a13609;
  font-size: 1.4705882353rem;
  line-height: 1.32;
  font-weight: 300;
}
.open-source .content-text:nth-child(3) p:last-child {
  display: flex;
  margin-top: 2.3529411765rem;
  margin-bottom: 0;
}
.project-board {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to bottom, #414141 0%, #333 100%);
  color: #fff;
  z-index: 1;
  padding: 10px;
  max-width: 320px;
}
.project-board .project-board__row {
  height: 36px;
  padding: 5px;
}
.project-board .project-board__row--title {
  padding-left: 46px;
  background-size: 30px, auto;
  background-position: 5px center, top left;
  font-size: 1.1764705882rem;
}
.project-board .project-board__flap {
  padding: 2px;
  width: 19px;
  height: 26px;
  font-size: 18px;
  line-height: 22px;
}
@media (min-width: 360px) {
  .project-board {
    padding: 10px;
    max-width: 360px;
  }
  .project-board .project-board__row {
    height: 39px;
    padding: 5px;
  }
  .project-board .project-board__row--title {
    padding-left: 52px;
    background-size: 34px, auto;
    background-position: 5px center, top left;
    font-size: 1.1764705882rem;
  }
  .project-board .project-board__flap {
    padding: 2px;
    width: 21px;
    height: 29px;
    font-size: 21px;
    line-height: 25px;
  }
}
@media (min-width: 375px) {
  .project-board {
    padding: 10px;
    max-width: 375px;
  }
  .project-board .project-board__row {
    height: 40px;
    padding: 5px;
  }
  .project-board .project-board__row--title {
    padding-left: 53px;
    background-size: 35px, auto;
    background-position: 5px center, top left;
    font-size: 1.1764705882rem;
  }
  .project-board .project-board__flap {
    padding: 2px;
    width: 22px;
    height: 30px;
    font-size: 22px;
    line-height: 26px;
  }
}
@media (min-width: 576px) {
  .project-board {
    padding: 10px;
    max-width: 510px;
  }
  .project-board .project-board__row {
    height: 61px;
    padding: 10px 15px;
  }
  .project-board .project-board__row--title {
    padding-left: 81px;
    background-size: 48px, auto;
    background-position: 15px center, top left;
    font-size: 1.7647058824rem;
  }
  .project-board .project-board__flap {
    padding: 2px;
    width: 30px;
    height: 41px;
    font-size: 33px;
    line-height: 37px;
    padding-right: 3px;
  }
}
@media (min-width: 768px) {
  .project-board {
    padding: 10px;
    max-width: 690px;
  }
  .project-board .project-board__row {
    height: 78px;
    padding: 10px 15px;
  }
  .project-board .project-board__row--title {
    padding-left: 104px;
    background-size: 65px, auto;
    background-position: 15px center, top left;
    font-size: 1.7647058824rem;
  }
  .project-board .project-board__flap {
    padding: 2px;
    width: 42px;
    height: 58px;
    font-size: 50px;
    line-height: 54px;
    padding-right: 3px;
  }
}
@media (min-width: 992px) {
  .project-board {
    padding: 10px;
    max-width: 500px;
    margin-top: 1.1764705882rem;
    min-width: 500px;
  }
  .project-board .project-board__row {
    height: 60px;
    padding: 10px 15px;
  }
  .project-board .project-board__row--title {
    padding-left: 80px;
    background-size: 47px, auto;
    background-position: 15px center, top left;
    font-size: 1.7647058824rem;
  }
  .project-board .project-board__flap {
    padding: 2px;
    width: 29px;
    height: 40px;
    font-size: 32px;
    line-height: 36px;
  }
}
.project-board--small {
  max-width: 100%;
  margin-left: 0;
}
@media (min-width: 992px) {
  .project-board--small {
    width: 100%;
    min-width: auto;
    padding: 10px;
    max-width: 450px;
  }
  .project-board--small .project-board__row {
    height: 47px;
    padding: 5px 10px;
  }
  .project-board--small .project-board__row--title {
    padding-left: 68px;
    background-size: 42px, auto;
    background-position: 10px center, top left;
    font-size: 1.7647058824rem;
  }
  .project-board--small .project-board__flap {
    padding: 1px;
    width: 27px;
    height: 37px;
    font-size: 31px;
    line-height: 35px;
  }
}
@media (min-width: 1200px) {
  .project-board--small {
    padding: 10px;
    max-width: 350px;
  }
  .project-board--small .project-board__row {
    height: 38px;
    padding: 5px 10px;
  }
  .project-board--small .project-board__row--title {
    padding-left: 55px;
    background-size: 33px, auto;
    background-position: 10px center, top left;
    font-size: 1.1764705882rem;
  }
  .project-board--small .project-board__flap {
    padding: 1px;
    width: 20px;
    height: 28px;
    font-size: 22px;
    line-height: 26px;
  }
}
@media (min-width: 1200px) {
  .project-board--big {
    padding: 15px;
    max-width: 650px;
  }
  .project-board--big .project-board__row {
    height: 82px;
    padding: 15px 20px;
  }
  .project-board--big .project-board__row--title {
    padding-left: 104px;
    background-size: 61px, auto;
    background-position: 20px center, top left;
    font-size: 1.7647058824rem;
  }
  .project-board--big .project-board__flap {
    padding: 2px;
    width: 38px;
    height: 52px;
    font-size: 44px;
    line-height: 48px;
    padding-right: 3px;
  }
}
.project-board__inner {
  display: flex;
  flex-direction: column;
  border: 2px solid #212125;
}
.project-board__row {
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, #414141 0%, #333 100%);
}
.project-board__row:not(:first-child) {
  border-top: 1px solid #1e1e22;
}
.project-board__row--title {
  padding-left: 50px;
  background: url("../images/icon-plane-yellow.svg") no-repeat 10px center/auto 65%, linear-gradient(to bottom, #414141 0%, #333 100%);
  color: #ffcc02;
  font-family: "Fira Code", sans-serif;
  font-size: 1.1764705882rem;
  will-change: contents;
}
.project-board__row--title::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 1px;
  margin-left: 5px;
  background-color: currentColor;
  animation: 1s blink step-end infinite;
}
.project-board__row--title-animating::after {
  animation: none;
}
.project-board__row--title-hide-cursor::after {
  display: none !important;
}
.project-board__flap {
  background: url("../images/project-board-letter.svg") no-repeat;
}
.project-board__flap-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.project-board__flap-half {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(50% - 1px);
  overflow: hidden;
  z-index: 10;
}
.project-board__flap-half--prev {
  top: 50%;
  background-position: center bottom;
}
.project-board__flap-half--back, .project-board__flap-half--front {
  height: 50%;
  will-change: transform;
  z-index: 20;
}
.project-board__flap-half--front {
  border-top: 1px solid #000;
  transform: scaleY(1);
  transform-origin: center bottom;
}
.project-board__flap-half--back {
  border-bottom: 1px solid #000;
  transform: scaleY(0);
  top: 50%;
  background-position: center bottom;
  transform-origin: center top;
}
.project-board__flap-half-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200%;
}
.project-board__flap-half--front, .project-board__flap-half--next {
  display: block;
  background: url("../images/project-board-half-top.svg") no-repeat center bottom/100% auto;
}
.project-board__flap-half--front .project-board__flap-half-text, .project-board__flap-half--next .project-board__flap-half-text {
  top: 0;
}
.project-board__flap-half--back, .project-board__flap-half--prev {
  display: block;
  background: url("../images/project-board-half-bottom.svg") no-repeat center top/100% auto;
}
.project-board__flap-half--back .project-board__flap-half-text, .project-board__flap-half--prev .project-board__flap-half-text {
  top: -100%;
}
.project-board__flap-animated .project-board__flap-half--front {
  animation: 0.06s ease-in flipFront;
}
.project-board__flap-animated .project-board__flap-half--back {
  animation: 0.06s linear flipBack;
}
.project-board__text {
  width: 100%;
}

@keyframes flipFront {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes flipBack {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.splash {
  padding-top: 20.5882352941rem;
  background-color: #d7dbcc;
  background-position: center 30px;
  background-size: 800px auto;
  background-repeat: no-repeat;
  background-image: url("../images/intro-bg.png");
  background-image: image-set(url("../images/intro-bg.webp") 1x, url("../images/intro-bg.png") 1x);
}
@media (min-width: 992px) {
  .splash {
    padding-top: 29.8823529412rem;
    padding-bottom: 4.7058823529rem;
    background-size: 1600px auto;
  }
}
.splash .container, .splash .header__inside {
  display: flex;
  flex-wrap: wrap;
}
.splash .content-text:nth-child(1) {
  width: 100%;
}
.splash .content-text:nth-child(1) p:first-child {
  margin-bottom: 1.1764705882rem;
  color: #2d3f4b;
}
@media (min-width: 992px) {
  .splash .content-text:nth-child(2) {
    width: 50%;
    padding-right: 2.7058823529rem;
  }
}
.splash .content-text:nth-child(2) p:first-child {
  font-size: 1.1764705882rem;
  font-weight: 300;
  line-height: 1.32;
}
@media (min-width: 992px) {
  .splash .content-text:nth-child(2) p:first-child {
    font-size: 1.4705882353rem;
  }
}
.splash .content-text:nth-child(2) p:not(:first-child) {
  color: #2d3f4b;
}
.splash .content-text:nth-child(3) {
  position: relative;
  padding: 1.4705882353rem 0 1.7647058824rem 0;
}
@media (min-width: 992px) {
  .splash .content-text:nth-child(3) {
    width: 50%;
    padding: 1.4705882353rem 0 1.7647058824rem 2.7058823529rem;
  }
}
.splash .content-text:nth-child(3)::before {
  content: "";
  display: block;
  position: absolute;
  left: -1000em;
  right: -1000em;
  top: 0;
  height: 100%;
  background-color: #f1ecd4;
  pointer-events: none;
}
@media (min-width: 992px) {
  .splash .content-text:nth-child(3)::before {
    left: 0;
  }
}
.splash .content-text:nth-child(3) > * {
  position: relative;
  z-index: 1;
}
.splash .content-text:nth-child(3) h2, .splash .team .swiper .content-text:nth-child(3) p:nth-child(1), .team .swiper .splash .content-text:nth-child(3) p:nth-child(1) {
  margin-bottom: 1rem;
}
.splash .content-text:nth-child(3) h2 + p, .splash .team .swiper .content-text:nth-child(3) p:nth-child(1) + p, .team .swiper .splash .content-text:nth-child(3) p:nth-child(1) + p {
  margin-bottom: 1rem;
  color: #2d3f4b;
}
.splash .content-text:nth-child(3) ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.splash .content-text:nth-child(3) li {
  margin: 0;
  color: #2d3f4b;
  line-height: 1.9411764706;
}
.splash .content-text:nth-child(3) li::before {
  content: "//";
  margin-right: 5px;
  color: #a13609;
}

.spotlight {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s;
}
.spotlight--active {
  opacity: 1;
}

.team {
  position: relative;
  padding-bottom: 2.3529411765rem;
}
@media (min-width: 992px) {
  .team {
    padding-bottom: 3.8823529412rem;
  }
}
.team::before {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(32.4117647059rem - 2px);
  left: 0;
  z-index: -1;
  height: 2.5882352941rem;
  width: 100%;
  background: url("../images/team-bg-top.svg") center bottom/100% 100% no-repeat;
  pointer-events: none;
}
@media (min-width: 576px) {
  .team::before {
    bottom: 32.4117647059rem;
  }
}
@media (min-width: 992px) {
  .team::before {
    height: 5.1764705882rem;
  }
}
.team::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 32.4117647059rem;
  width: 100%;
  background-color: #d7dbcc;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .team .container, .team .header__inside {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .team .container > .content-text:first-child, .team .header__inside > .content-text:first-child {
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.team .container > .content-text:first-child p, .team .header__inside > .content-text:first-child p {
  margin-bottom: 1.1764705882rem;
}
.team .swiper {
  position: relative;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}
.team .swiper .content-text figure {
  display: flex;
  justify-content: center;
  margin-bottom: 1.7647058824rem;
}
.team .swiper .content-text figure img {
  max-width: 15rem;
  border-radius: 50%;
}
.team .swiper .content-text p:nth-child(1) {
  margin-bottom: 0.5882352941rem;
  text-align: center;
}
.team .swiper .content-text p:nth-child(2) {
  color: #2d3f4b;
  text-align: center;
}
.team .swiper .content-text p:nth-child(3) {
  margin-bottom: 1.5882352941rem;
  color: #2d3f4b;
  text-align: center;
}
@media (min-width: 576px) {
  .team .swiper .content-text p:nth-child(3) {
    text-align: left;
  }
}
.team .swiper .content-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
@media (min-width: 576px) {
  .team .swiper .content-text ul {
    text-align: left;
  }
}
.team .swiper .content-text li {
  margin: 0;
}
.team .swiper .content-text li:not(:last-child) {
  margin-bottom: 10px;
}
.team .swiper .content-text a {
  padding-left: 1.2941176471rem;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 12px auto;
  font-family: "Fira Code", sans-serif;
  font-size: 0.8235294118rem;
  font-weight: 500;
}
.team .swiper .content-text a.email {
  background-image: url("../images/icon-email-team.svg");
}
.team .swiper .content-text a.github-link {
  background-image: url("../images/icon-github-team.svg");
}
.team .swiper-button-prev, .team .swiper-button-next {
  position: absolute;
  top: calc(50% - 8px);
  width: 32px;
  height: 32px;
  background: url("../images/icon-arrow.svg") center/auto no-repeat;
  cursor: pointer;
  z-index: 10;
}
.team .swiper-button-prev {
  right: calc(100% - 32px);
  transform: scale(-1, 1);
}
@media (min-width: 576px) {
  .team .swiper-button-prev {
    right: calc(100% + 10px);
  }
}
@media (min-width: 1200px) {
  .team .swiper-button-prev {
    right: calc(100% + 5.2941176471rem);
  }
}
.team .swiper-button-next {
  left: calc(100% - 32px);
}
@media (min-width: 576px) {
  .team .swiper-button-next {
    left: calc(100% + 10px);
  }
}
@media (min-width: 1200px) {
  .team .swiper-button-next {
    left: calc(100% + 5.2941176471rem);
  }
}
.team .swiper-button-lock {
  display: none;
}
.team .swiper-button-disabled {
  opacity: 0.25;
}
.team .swiper .tns-nav {
  display: flex;
  justify-content: center;
  margin-top: 3.5294117647rem;
}
.team .swiper .tns-nav button {
  display: block;
  margin: 0 10px;
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
}
.team .swiper .tns-nav button.tns-nav-active {
  background-color: #a13609;
}

.technologies {
  padding: 2.3529411765rem 0;
}
@media (min-width: 992px) {
  .technologies {
    padding: 4.7058823529rem 0;
  }
}
.technologies .content-gallery ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 992px) {
  .technologies .content-gallery ul {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.technologies .content-gallery li {
  margin: 0 10px;
}
@media (min-width: 768px) {
  .technologies .content-gallery li {
    margin: 0 15px;
  }
}
@media (min-width: 1200px) {
  .technologies .content-gallery li {
    margin: 0;
  }
}
.technologies .content-gallery img {
  max-width: 100px;
  max-height: 50px;
}
@media (min-width: 768px) {
  .technologies .content-gallery img {
    max-width: 100%;
    max-height: 100%;
  }
}