.open-source {
    position: relative;
    padding-top: $section-padding;

    @include media-breakpoint-up(lg) {
        padding-bottom: $section-padding * 0.5;
    }

    .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    .content-text:nth-child(1) {
        width: 100%;
        margin-bottom: calculateRem(40px);

        @include media-breakpoint-up(lg) {
            margin-bottom: calculateRem(140px);
        }

        p:first-child {
            margin-bottom: calculateRem(20px);
            color: $secondary-color;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    .content-text:nth-child(2) {
        position: relative;
        padding: calculateRem(40px) 0;
        color: $secondary-color;

        @include media-breakpoint-up(lg) {
            position: static;
            max-width: calculateRem(540px);
            margin-top: calculateRem(-60px);
            padding: calculateRem(40px) calculateRem(40px) calculateRem(60px) calculateRem(40px);
            background-color: rgba(84, 120, 108, 0.4);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -1000em; // align to the left edge of the window
            right: -1000em; // align to the right edge of the window
            height: 100%;
            z-index: -1;
            background-color: rgba(84, 120, 108, 0.4);
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        h2 {
            @extend .h1;
            color: $primary-color;
        }

        p {
            font-size: calculateRem(25px);
            font-weight: $font-weight-light;
            line-height: 1.32;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .content-text:nth-child(3) {
        position: relative;
        padding: calculateRem(40px) 0;
        color: $secondary-color;

        @include media-breakpoint-up(lg) {
            padding: calculateRem(60px) 0 calculateRem(60px) calculateRem(60px);
            max-width: calc(100% - #{calculateRem(540px)});
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: calculateRem(-190px);
            top: 0;
            right: -1000em; // align to the right edge of the window
            height: 100%;
            z-index: -1;
            background: #f1ecd4;
            pointer-events: none;
        }

        h2 {
            @extend .h1;
        }

        p:nth-child(2) {
            color: $primary-color;
            font-size: calculateRem(25px);
            line-height: 1.32;
            font-weight: $font-weight-light;
        }

        p:last-child {
            display: flex;
            margin-top: calculateRem(40px);
            margin-bottom: 0;

            a {
                @extend %big-link;
            }
        }
    }
}
