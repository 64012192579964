.github {
    padding-bottom: $section-padding * 0.5;

    @include media-breakpoint-up(lg) {
        padding-bottom: $section-padding;
    }

    .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-start;
            padding-top: calculateRem(60px); // negative margin of first text element
        }
    }

    .content-text:first-child {
        position: relative;
        padding: calculateRem(40px) 0;
        color: $secondary-color;

        @include media-breakpoint-up(lg) {
            position: static;
            max-width: calculateRem(540px);
            margin-top: calculateRem(-60px);
            padding: calculateRem(40px) calculateRem(30px) calculateRem(33px) calculateRem(30px);
            background-color: rgba(84, 120, 108, 0.4);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -1000em; // align to the left edge of the window
            right: -1000em; // align to the right edge of the window
            height: 100%;
            z-index: -1;
            background-color: rgba(84, 120, 108, 0.4);
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        h2 {
            @extend .h1;
            color: $primary-color;
        }

        p:first-child {
            margin-bottom: calculateRem(20px);
        }

        p:last-child {
            margin-bottom: 0;
            margin-top: $vr;

            a {
                @extend %link-arrow;
                width: auto;
                height: auto;
                padding-left: calculateRem(68px);
                background-position: left center;
                color: $primary-color;
                transition: all .15s;

                &:hover {
                    padding-left: calculateRem(78px);
                    color: inherit;
                }
            }
        }
    }

    &-feed {
        position: relative;
        padding: calculateRem(40px) 0;

        @include media-breakpoint-up(lg) {
            max-width: calc(100% - #{calculateRem(540px)});
            min-height: calculateRem(569px); // ::after height + its vertical offset
            padding: calculateRem(40px) calculateRem(40px) calculateRem(80px) calculateRem(40px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -1000em; // align to the left edge of the window
            right: -1000em; // align to the right edge of the window
            height: 100%;
            z-index: -1;
            background: #faf8ea;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                left: calculateRem(-190px);
            }
        }

        @include media-breakpoint-up(lg) {
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: calculateRem(-160px);
                bottom: $vr;
                width: calculateRem(137px);
                height: calculateRem(509px);
                z-index: -1;
                background: url('../images/github-logo-white.svg') center/auto no-repeat;
                pointer-events: none;
            }
        }

        &__headline {
            color: $secondary-color;
        }

        &__item {
            margin-bottom: $vr;

            &:last-child {
                margin-bottom: calculateRem(40px);
            }

            &:not(:last-child) {
                padding-bottom: calculateRem(24px);
                background: url('../images/divider.svg') center bottom/100% auto no-repeat;
            }

            &-date {
                @extend %text-meta;
                margin-bottom: calculateRem(10px);
            }

            &-name {
                margin-bottom: calculateRem(10px);
                font-size: calculateRem(20px);

                a {
                    @extend %link-underline;
                }
            }

            &-description {
                margin-bottom: calculateRem(20px);
                color: $secondary-color;

                a {
                    @extend %link-underline;
                }
            }

            &-link {
                @extend %link-arrow;
            }
        }

        &__more {
            display: flex;

            &-link {
                @extend %big-link;
            }
        }
    }
}
