.splash {
    padding-top: calculateRem(350px);
    background-color: #d7dbcc;
    background-position: center 30px;
    background-size: 800px auto;
    background-repeat: no-repeat;
    background-image: url('../images/intro-bg.png');
    background-image: image-set(
        url('../images/intro-bg.webp') 1x /* type('image/webp') */,
        url('../images/intro-bg.png') 1x /* type('image/png') */
    );

    @include media-breakpoint-up(lg) {
        padding-top: calculateRem(508px);
        padding-bottom: $section-padding;
        background-size: 1600px auto;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .content-text:nth-child(1) {
        width: 100%;

        p:first-child {
            margin-bottom: calculateRem(20px);
            color: $secondary-color;
        }
    }

    .content-text:nth-child(2) {
        @include media-breakpoint-up(lg) {
            width: 50%;
            padding-right: calculateRem(46px);
        }

        p:first-child {
            font-size: calculateRem(20px);
            font-weight: $font-weight-light;
            line-height: 1.32;

            @include media-breakpoint-up(lg) {
                font-size: calculateRem(25px);
            }
        }

        p:not(:first-child) {
            color: $secondary-color;
        }
    }

    .content-text:nth-child(3) {
        position: relative;
        padding: calculateRem(25px) 0 calculateRem(30px) 0;

        @include media-breakpoint-up(lg) {
            width: 50%;
            padding: calculateRem(25px) 0 calculateRem(30px) calculateRem(46px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -1000em; // align to the left edge of the window
            right: -1000em; // align to the right edge of the window
            top: 0;
            height: 100%;
            background-color: #f1ecd4;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                left: 0;
            }
        }

        > * {
            position: relative;
            z-index: 1;
        }

        h2 {
            margin-bottom: 1rem;

            + p {
                margin-bottom: 1rem;
                color: $secondary-color;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            margin: 0;
            color: $secondary-color;
            line-height: 1.9411764706;

            &::before {
                content: '//';
                margin-right: 5px;
                color: $primary-color;
            }
        }
    }
}
