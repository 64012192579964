@use "sass:math";

$flaps-count: 15;
$board-border-width: 2px;

@mixin project-board($board-width, $board-padding, $row-padding, $flap-padding) {
    $flap-width: floor(math.div($board-width - ($board-padding * 2) - ($row-padding * 2) - ($board-border-width * 2), $flaps-count));
    $flap-height: round($flap-width * 1.38);

    padding: $board-padding;
    max-width: $board-width;

    .project-board__row {
        @if $row-padding == 5px {
            height: $flap-height + ($row-padding * 2);
            padding: $row-padding;
        } @else {
            height: $flap-height + (($row-padding - 5px) * 2);
            padding: ($row-padding - 5px) $row-padding;
        }

        &--title {
            padding-left: $row-padding + round($board-width * 0.094) + round($board-width * 0.035);
            background-size: round($board-width * 0.094), auto;
            background-position: $row-padding center, top left;

            @if $board-width > 400px {
                font-size: calculateRem(30px);
            } @else if $board-width > 600px {
                font-size: calculateRem(34px);
            } @else {
                font-size: calculateRem(20px);
            }
        }
    }

    .project-board__flap {
        padding: $flap-padding;
        width: $flap-width;
        height: $flap-height;
        font-size: $flap-height - ($flap-padding * 2) - 4px;
        line-height: $flap-height - ($flap-padding * 2);

        @if $board-width > 500px {
            padding-right: $flap-padding + 1px;
        }
    }
}

.project-board {
    $this: &;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #414141 0%, #333 100%);
    color: #fff;
    z-index: 1;
    @include project-board(320px, 10px, 5px, 2px);

    @media (min-width: 360px) {
        @include project-board(360px, 10px, 5px, 2px);
    }

    @media (min-width: 375px) {
        @include project-board(375px, 10px, 5px, 2px);
    }

    @include media-breakpoint-up(sm) {
        @include project-board(510px, 10px, 15px, 2px);
    }

    @include media-breakpoint-up(md) {
        @include project-board(690px, 10px, 15px, 2px);
    }

    @include media-breakpoint-up(lg) {
        @include project-board(500px, 10px, 15px, 2px);
        margin-top: calculateRem(20px);
        min-width: 500px;
    }

    &--small {
        max-width: 100%;
        margin-left: 0;

        @include media-breakpoint-up(lg) {
            width: 100%;
            min-width: auto;
            @include project-board(450px, 10px, 10px, 1px);
        }

        @include media-breakpoint-up(xl) {
            @include project-board(350px, 10px, 10px, 1px);
        }
    }

    &--big {
        @include media-breakpoint-up(xl) {
            @include project-board(650px, 15px, 20px, 2px);
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        border: $board-border-width solid #212125;
    }

    &__row {
        display: flex;
        align-items: center;
        background: linear-gradient(to bottom, #414141 0%, #333 100%);

        &:not(:first-child) {
            border-top: 1px solid #1e1e22;
        }

        &--title {
            padding-left: 50px;
            background: url('../images/icon-plane-yellow.svg') no-repeat 10px center/auto 65%, linear-gradient(to bottom, #414141 0%, #333 100%);
            color: #ffcc02;
            font-family: $font-family-headings;
            font-size: calculateRem(20px);
            will-change: contents;

            &::after {
                content: '';
                display: inline-block;
                height: 100%;
                width: 1px;
                margin-left: 5px;
                background-color: currentColor;
                animation: 1s blink step-end infinite;
            }

            &-animating::after {
                animation: none;
            }

            &-hide-cursor::after {
                display: none !important;
            }
        }
    }

    &__flap {
        background: url('../images/project-board-letter.svg') no-repeat;

        &-inner {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &-half {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: calc(50% - 1px);
            overflow: hidden;
            z-index: 10;

            &--prev {
                top: 50%;
                background-position: center bottom;
            }

            &--back,
            &--front {
                height: 50%;
                will-change: transform;
                z-index: 20;
            }

            &--front {
                border-top: 1px solid #000;
                transform: scaleY(1);
                transform-origin: center bottom;
            }

            &--back {
                border-bottom: 1px solid #000;
                transform: scaleY(0);
                top: 50%;
                background-position: center bottom;
                transform-origin: center top;
            }

            &-text {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 200%;
            }

            &--front,
            &--next {
                display: block;
                background: url('../images/project-board-half-top.svg') no-repeat center bottom/100% auto;

                #{$this}__flap-half-text {
                    top: 0;
                }
            }

            &--back,
            &--prev {
                display: block;
                background: url('../images/project-board-half-bottom.svg') no-repeat center top/100% auto;

                #{$this}__flap-half-text {
                    top: -100%;
                }
            }
        }

        &-animated #{$this}__flap-half--front {
            animation: .06s ease-in flipFront;
        }

        &-animated #{$this}__flap-half--back {
            animation: .06s linear flipBack;
        }
    }

    &__text {
        width: 100%;
    }
}

@keyframes flipFront {
    0% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0);
    }
}
@keyframes flipBack {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
