.footer {
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: calculateRem(44px); // pseudo element height
        padding-bottom: 15vw;

        @include media-breakpoint-up(lg) {
            margin-top: calculateRem(88px); // pseudo element height
            padding-bottom: calculateRem(215px);  // pseudo element height
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: calc(32vw - 2px); // ::after height
            left: 0;
            height: calculateRem(44px);
            width: 100%;
            background: url('../images/footer-bg-top.svg') center bottom/100% 100% no-repeat;
            pointer-events: none;

            @include media-breakpoint-up(sm) {
                bottom: 32vw; // ::after height
            }

            @include media-breakpoint-up(lg) {
                height: calculateRem(88px);
                bottom: calculateRem(356px); // ::after height
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -5px; // make sure there is no gap to the footer bar
            left: 0;
            height: calc(32vw + 10px);
            width: 100%;
            background-color: #bbc9c4;
            background-position: center bottom;
            background-size: 200vw auto;
            background-repeat: no-repeat;
            background-image: url('../images/footer-bg.png');
            background-image: image-set(
                url('../images/footer-bg.webp') 1x, /* type('image/webp') */
                url('../images/footer-bg.png') 1x /* type('image/png')*/
            );
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                background-size: 1600px auto;
                height: calculateRem(356px + 10px);
            }
        }
    }

    &__text {
        p:first-child {
            margin-bottom: calculateRem(20px);
            color: $secondary-color;
        }

        p:last-child {
            font-size: calculateRem(20px);
            margin-bottom: $section-padding * 0.5;
            font-weight: $font-weight-light;
            line-height: 1.32;

            @include media-breakpoint-up(lg) {
                font-size: calculateRem(25px);
            }

            a {
                @extend %link-underline;
            }
        }
    }

    &__bar {
        position: relative;
        z-index: 20;
        border-top: 4px solid $primary-color;
        background: $secondary-color;
        color: #fff;
        font-family: $font-family-headings;
        font-size: calculateRem(11px);
        font-weight: $font-weight-semibold;

        .container {
            display: flex;
            align-items: center;
            padding-top: calculateRem(16.5px);
            padding-bottom: calculateRem(16.5px);
        }
    }

    &__copyright {
        margin-right: auto;
    }

    &__social {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-right: calculateRem(20px);

        &-link {
            display: block;
            width: 20px;
            height: 16px;
            text-indent: -999em;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            text-decoration: none;

            &:not(:last-child) {
                margin-right: calculateRem(15px);
            }

            &--facebook {
                background-image: url("../images/social--facebook.svg");
            }

            &--linkedin {
                background-image: url("../images/social--linkedin.svg");
            }

            &--github {
                background-image: url("../images/social--github.svg");
            }
        }
    }

    &__links {
        display: flex;
        gap: calculateRem(20px);
    }

    &__link {
        @extend %link-underline;
        color: #fff;
    }
}
