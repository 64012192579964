$this: '.construction-pages';

#{$this}--overview {
    position: relative;
    min-height: calculateRem(790px);
    margin-bottom: calculateRem(44px); // pseudo element height
    padding-top: $section-padding * 0.5;
    padding-bottom: $section-padding * 0.5;
    background: $blue-color url('../images/constructions-slider-bg.svg') center/auto 716px no-repeat;
    color: #fff;

    @include media-breakpoint-up(lg) {
        margin-bottom: calculateRem(88px); // pseudo element height
        padding-top: $section-padding;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        z-index: -1;
        height: calculateRem(44px);
        width: 100%;
        background: url('../images/constructions-slider-bg-bottom.svg') center bottom/100% 100% no-repeat;
        pointer-events: none;

        @include media-breakpoint-up(sm) {
            top: 100%;
        }

        @include media-breakpoint-up(lg) {
            height: calculateRem(88px);
        }
    }

    > .container {
        position: relative;
    }

    #{$this}__text:first-child p {
        margin-bottom: calculateRem(20px);
    }

    #{$this}__content {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    #{$this}__slider {
        @include media-breakpoint-up(lg) {
            position: relative;
            padding: 0 $grid-gutter;
            width: calc(100% - #{calculateRem(500px)});

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: $grid-gutter * 0.5;
                left: calculateRem(-190px);
                display: block;
                background-color: rgba(213, 192, 139, .2);
                pointer-events: none;
            }
        }
    }

    .swiper {
        &-container {
            .swiper-slide {
                padding-top: calculateRem(40px);
                padding-bottom: calculateRem(20px);
                color: #fff;

                @include media-breakpoint-up(lg) {
                    position: relative;
                    padding-top: calculateRem(80px);

                    > * {
                        position: relative;
                        margin-right: calculateRem(30px);
                        z-index: 1;
                    }
                }

                h2 {
                    margin-bottom: calculateRem(20px);
                }

                p {
                    margin-bottom: calculateRem(30px);

                    @include media-breakpoint-up(lg) {
                        margin-bottom: calculateRem(60px);
                    }
                }

                p:last-child {
                    margin-bottom: 0;

                    a {
                        @extend %big-link;
                        border-color: #fff;
                        color: #fff;

                        &:hover {
                            background-color: #fff;
                            color: $blue-color;
                        }
                    }
                }
            }
        }

        &-button-prev,
        &-button-next {
            position: absolute;
            top: calc(50% - 8px);
            width: 32px;
            height: 32px;
            padding: 0;
            background: url('../images/icon-arrow-white.svg') center/auto no-repeat;
            border: none;
            cursor: pointer;
            z-index: 10;

            &:disabled {
                opacity: .25;
            }
        }

        &-button-prev {
            right: calc(100% - 32px);
            transform: scale(-1, 1);

            @include media-breakpoint-up(sm) {
                right: calc(100% + 10px);
            }

            @include media-breakpoint-up(xl) {
                right: calc(100% + #{calculateRem(90px)});
            }
        }

        &-button-next {
            left: calc(100% - 32px);

            @include media-breakpoint-up(sm) {
                left: calc(100% + 10px);
            }

            @include media-breakpoint-up(xl) {
                left: calc(100% + #{calculateRem(90px)});
            }
        }

        &-pagination {
            display: flex;
            justify-content: center;
            margin-top: $vr;

            button {
                display: block;
                margin: 0 10px;
                padding: 0;
                width: 10px;
                height: 10px;
                background-color: #9ec7e6;
                opacity: 1;
                border-radius: 100%;
                border: none;
                cursor: pointer;

                &.tns-nav-active {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

#{$this}--related {
    padding-top: $section-padding * 0.5;
    padding-bottom: $section-padding * 0.5;
    background-color: #f2f2f2;

    @include media-breakpoint-up(lg) {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
    }

    #{$this}__text {
        width: 100%;

        p:first-child {
            margin-bottom: calculateRem(20px);
            color: $secondary-color;
        }

        h2 {
            @extend .h1;
        }
    }

    #{$this}__slider {
        .image_container {
            margin-bottom: 0;

            img {
                width: 100%;
            }
        }

        h3 {
            margin-bottom: 0;
            padding: calculateRem(30px) calculateRem(20px) calculateRem(23px) calculateRem(20px);
            font-size: calculateRem(25px);
            background-color: #fff;
        }

        p {
            margin-bottom: 0;
            padding: 0 calculateRem(20px) calculateRem(20px) calculateRem(20px);
            background-color: #fff;
            color: $secondary-color;
            font-size: calculateRem(14px);
            line-height: 1.2857142857;
            text-align: justify;
        }

        p:last-child {
            padding-bottom: calculateRem(40px);

            a {
                @extend %link-underline;
                padding-left: 40px;
                background: url('../images/link-arrow-short.svg') left center/20px auto no-repeat;
                font-family: $font-family-headings;
                font-weight: $font-weight-semibold;
            }
        }
    }

    .swiper {
        position: relative;

        .tns-nav {
            display: flex;
            justify-content: center;
            margin-top: $vr;

            &-lock {
                display: none;
            }

            button {
                display: block;
                margin: 0 10px;
                padding: 0;
                width: 10px;
                height: 10px;
                background-color: #9ec7e6;
                border: none;
                opacity: 1;
                border-radius: 100%;
                cursor: pointer;

                &.tns-nav-active {
                    background-color: $secondary-color;
                }
            }
        }
    }
}
