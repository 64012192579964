.construction-details {
    position: relative;
    padding-top: $section-padding;
    padding-bottom: $section-padding * 0.5;

    @include media-breakpoint-up(lg) {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(#{calculateRem(300px)} + 2px);
        left: 0;
        z-index: -1;
        height: calculateRem(44px);
        width: 100%;
        background: url('../images/construction-details-bg-top.svg') center bottom/100% 100% no-repeat;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            height: calculateRem(88px);
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        height: calc(100% - #{calculateRem(344px)}); // 100% minus ::before top + height
        width: 100%;
        background-color: $blue-color;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            height: calc(100% - #{calculateRem(388px)}); // 100% minus ::before top + height
        }
    }

    > .container {
        display: flex;
        flex-wrap: wrap;
    }

    .content-text:first-child {
        width: 100%;
        margin-bottom: calculateRem(40px);

        @include media-breakpoint-up(lg) {
            margin-bottom: calculateRem(220px);
        }

        p:first-child {
            margin-bottom: calculateRem(20px);
            color: $secondary-color;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    .construction-details-element {
        width: 100%;

        @include media-breakpoint-up(lg) {
            position: relative;
            width: 50%;
            padding-left: $grid-gutter * 0.5;
            padding-right: $grid-gutter * 0.5;
        }

        .project-board {
            margin-top: 0;
            margin-bottom: 2rem;

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: calculateRem(-160px);
                right: $grid-gutter * 2;
                margin-bottom: 0;
            }
        }
    }

    .content-text:last-child {
        position: relative;
        width: 100%;
        color: #fff;

        @include media-breakpoint-up(lg) {
            width: 50%;
            padding-left: $grid-gutter * 0.5;
            padding-right: $grid-gutter * 0.5;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: calculateRem(-560px);
            right: calculateRem(-425px);
            width: calculateRem(716px);
            height: calculateRem(1020px);
            background: url('../images/construction-details-bg-top-right.svg') center bottom/100% 100% no-repeat;
            pointer-events: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 50%;
            width: calculateRem(973px);
            height: calculateRem(295px);
            background: url('../images/construction-details-bg-bottom-left.svg') center bottom/100% 100% no-repeat;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                right: calc(100% + #{$grid-gutter * 2});
            }
        }

        h2 {
            margin-bottom: calculateRem(20px);
        }

        pre {
            margin-bottom: 0;
            color: #c6e7ff;
            font-family: $font-family-headings;
            white-space: normal;

            @include media-breakpoint-up(lg) {
                margin-top: calculateRem(80px);
            }
        }
    }

    a {
        @extend %link-underline-active;
    }
}
