.mod_article.shop {
    position: relative;
    padding-top: $section-padding * 0.5;
    padding-bottom: $section-padding * 0.5;
    background: $primary-color;
    color: #fff;

    .content-hyperlink {
        display: flex;
        justify-content: center;

        a {
            display: block;
            margin-bottom: 0;
            padding-right: 60px;
            font-size: calculateRem(25px);
            line-height: 35px;
            color: #fff;
            text-decoration: none;
            background: url("../images/shopping-cart.svg") 100% center/contain no-repeat;
            transition: transform .3s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}
