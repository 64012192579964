@import "defaults";

html {
    box-sizing: border-box;
    font-size: $font-size-mobile;

    @include media-breakpoint-up(md) {
        font-size: $font-size-tablet;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-desktop;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

body, form, figure, blockquote {
    margin: 0;
    padding: 0
}

img, svg, iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

img, svg {
    height: auto;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

hr {
    height: 1px;
    margin: 3.5em 0;
    border: none;
    background-color: $body-color;

    & + .content-text {
        margin-top: -1em;
    }
}

header, footer, main, nav, section, aside, article, figure, figcaption {
    display: block
}

html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {
    -webkit-text-size-adjust: none;
}

body, input, textarea, select {
    font-family: $font-family-body;
    font-weight: $font-weight-regular;
    line-height: 1.3;
    color: $body-color;
}

/* Vertical rhythm */
ul, ol, dd,
p, figure,
pre, table, fieldset {
    margin-top: 0;
    margin-bottom: $vr;
}

select, input, textarea {
    font-size: 99%;
}

strong {
    font-weight: $font-weight-semibold;
}

a {
    color: $blue-color;
    text-decoration: none;
    transition: color .15s;

    &:hover {
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-headings;
    font-size: inherit;
    line-height: inherit;
    font-weight: $font-weight-regular;
    margin: 0;
}

h1, .h1 {
    margin-bottom: calculateRem(30px);
    font-size: calculateRem(28px);
    line-height: 1.08333;

    @include media-breakpoint-up(md) {
        margin-bottom: calculateRem(40px);
        font-size: calculateRem(32px);
    }

    @include media-breakpoint-up(lg) {
        font-size: calculateRem(36px);
    }
}

h2, .h2 {
    margin-bottom: calculateRem(35px);
    font-size: calculateRem(25px);
    line-height: 1.32;
}

figcaption {
    padding: 5px 0;
    font-size: 14px;
    color: $body-color;
    text-align: left;
    letter-spacing:0.02em;
}

.main {
    overflow: hidden;
}

.overflow {
    overflow: visible;
}

.clear, #clear {
    height: .1px;
    font-size: .1px;
    line-height: .1px;
    clear: both
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gutter * 0.5;
    padding-right: $grid-gutter * 0.5;
}

.content-text a {
    @extend %link-underline;
}

.swiper-slide {
    box-sizing: border-box;
}

@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        %responsive-container-#{$breakpoint} {
            max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in $grid-breakpoints {
            @if ($extend-breakpoint) {
                .container#{breakpoint-infix($name, $grid-breakpoints)} {
                    @extend %responsive-container-#{$breakpoint};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}
