// Colors
@use "sass:math";

$primary-color: #a13609;
$secondary-color: #2d3f4b;
$blue-color: #457da7;

// Grid / layout
$grid-gutter: 30px;
$section-padding: calculateRem(80px);
$vr: calculateRem(30px);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
);

// Typings
$body-color: $primary-color;
$font-size-mobile: 14px;
$font-size-tablet: 15px;
$font-size-desktop: 17px;
$font-family-body: 'Fira Sans', sans-serif;
$font-family-headings: 'Fira Code', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;

// Functions
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Mixins
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Functions
@function calculateRem($size) {
    @return math.div($size, $font-size-desktop) * 1rem;
}

// Helper classes
%link-arrow {
    display: inline-block;
    width: 53px;
    height: 11px;
    background: url('../images/link-arrow.svg') no-repeat;
    text-decoration: none;

    &::after {
        display: none !important;
    }
}

%link-underline {
    display: inline-block;
    text-decoration: none;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background-color: currentColor;
        transition: width 0.3s;
    }

    &:hover,
    &.active {
        color: currentColor;
        text-decoration: none;

        &::after {
            width: 100%;
        }
    }
}

%link-underline-active {
    display: inline-block;
    text-decoration: none;
    color: currentColor;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transition: width 0.3s;
    }
}

%text-meta {
    color: $primary-color;
    font-family: $font-family-headings;
    font-size: calculateRem(11px);
}

%big-link {
    display: inline-block;
    padding: calculateRem(20px) calculateRem(40px);
    border: 1px solid $secondary-color;
    color: $secondary-color;
    font-family: $font-family-headings;
    text-decoration: none;
    white-space: nowrap;
    transition: all .15s;

    @include media-breakpoint-up(lg) {
        padding-left: calculateRem(60px);
        padding-right: calculateRem(60px);
    }

    &:hover {
        background-color: $secondary-color;
        color: #fff;
        text-decoration: none;
    }

    &::after {
        display: none !important;
    }
}
