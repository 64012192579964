.team {
    position: relative;
    padding-bottom: calculateRem(40px);

    @include media-breakpoint-up(lg) {
        padding-bottom: calculateRem(66px);
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(#{calculateRem(551px)} - 2px); // ::after height
        left: 0;
        z-index: -1;
        height: calculateRem(44px);
        width: 100%;
        background: url('../images/team-bg-top.svg') center bottom/100% 100% no-repeat;
        pointer-events: none;

        @include media-breakpoint-up(sm) {
            bottom: calculateRem(551px); // ::after height
        }

        @include media-breakpoint-up(lg) {
            height: calculateRem(88px);
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        height: calculateRem(551px);
        width: 100%;
        background-color: #d7dbcc;
        pointer-events: none;
    }

    .container {
        @include media-breakpoint-up(xl) {
            max-width: 100%;
        }

        > .content-text:first-child {
            @include media-breakpoint-up(xl) {
                max-width: 1140px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                padding-left: $grid-gutter * 0.5;
                padding-right: $grid-gutter * 0.5;
            }

            p {
                margin-bottom: calculateRem(20px);
            }
        }
    }

    h2 {
        @extend .h1;
    }

    .swiper {
        position: relative;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;

        .content-text {
            figure {
                display: flex;
                justify-content: center;
                margin-bottom: $vr;

                img {
                    max-width: calculateRem(255px);
                    border-radius: 50%;
                }
            }

            p:nth-child(1) {
                @extend h2;
                margin-bottom: calculateRem(10px);
                text-align: center;
            }

            p:nth-child(2) {
                @extend %text-meta;
                color: $secondary-color;
                text-align: center;
            }

            p:nth-child(3) {
                margin-bottom: calculateRem(27px);
                color: $secondary-color;
                text-align: center;

                @include media-breakpoint-up(sm) {
                    text-align: left;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: center;

                @include media-breakpoint-up(sm) {
                    text-align: left;
                }
            }

            li {
                margin: 0;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            a {
                @extend %link-underline;
                padding-left: calculateRem(22px);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 12px auto;
                font-family: $font-family-headings;
                font-size: calculateRem(14px);
                font-weight: $font-weight-semibold;

                &.email {
                    background-image: url('../images/icon-email-team.svg');
                }

                &.github-link {
                    background-image: url('../images/icon-github-team.svg');
                }
            }
        }

        &-button-prev,
        &-button-next {
            position: absolute;
            top: calc(50% - 8px);
            width: 32px;
            height: 32px;
            background: url('../images/icon-arrow.svg') center/auto no-repeat;
            cursor: pointer;
            z-index: 10;
        }

        &-button-prev {
            right: calc(100% - 32px);
            transform: scale(-1, 1);

            @include media-breakpoint-up(sm) {
                right: calc(100% + 10px);
            }

            @include media-breakpoint-up(xl) {
                right: calc(100% + #{calculateRem(90px)});
            }
        }

        &-button-next {
            left: calc(100% - 32px);

            @include media-breakpoint-up(sm) {
                left: calc(100% + 10px);
            }

            @include media-breakpoint-up(xl) {
                left: calc(100% + #{calculateRem(90px)});
            }
        }

        &-button-lock {
            display: none;
        }

        &-button-disabled {
            opacity: .25;
        }

        .tns-nav {
            display: flex;
            justify-content: center;
            margin-top: $vr * 2;

            button {
                display: block;
                margin: 0 10px;
                padding: 0;
                width: 10px;
                height: 10px;
                background-color: #fff;
                border: none;
                border-radius: 100%;
                cursor: pointer;

                &.tns-nav-active {
                    background-color: $primary-color;
                }
            }
        }
    }
}
