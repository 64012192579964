.boarding-pass {
    position: relative;
    max-width: 400px;
    margin-bottom: calculateRem(55px);
    margin-left: auto;
    margin-right: auto;
    background: url('../images/boarding-pass-mobile.svg') center/100% no-repeat;
    font-size: 8px;
    z-index: 10;

    @include media-breakpoint-up(sm) {
        max-width: 100%;
        font-size: 10px;
        background: url('../images/boarding-pass.svg') center/100% no-repeat;
    }

    @include media-breakpoint-up(md) {
        font-size: $font-size-mobile;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size-desktop;
    }

    &::before {
        content: '';
        display: block;
        padding-bottom: 108%;

        @include media-breakpoint-up(sm) {
            padding-bottom: 36%;
        }
    }

    &__codes {
        position: absolute;
        top: 20%;
        left: 5.4%;
        display: flex;
        align-items: center;
        flex: 1;
        color: $secondary-color;
        font-size: 36px;
        font-weight: $font-weight-semibold;
        line-height: 1;

        @include media-breakpoint-up(sm) {
            top: 27.5%;
            left: 3.6%;
            font-size: 2.12em;
        }

        &-image {
            margin-left: 0.2em;
            margin-right: 0.2em;

            @include media-breakpoint-up(sm) {
                margin-left: 0.56em;
                margin-right: 0.56em;
            }

            img {
                width: 0.67em;
            }
        }
    }

    &__github {
        $this: &;
        display: none;

        &:hover {
            text-decoration: none;

            #{$this}-address {
                color: $secondary-color;
            }
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 27.5%;
            left: 34.23%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
        }

        &-barcode {
            margin-right: 0.59em;

            img {
                width: 9em;

                @include media-breakpoint-up(sm) {
                    width: 12em;
                }

                @include media-breakpoint-up(lg) {
                    width: 15.06em;
                }
            }
        }

        &-icon img {
            width: 1.71em;
        }

        &-address {
            @extend %text-meta;
            margin-top: 5px;
            width: 100%;
            font-size: .6em;
            transition: color .15s;

            @include media-breakpoint-up(sm) {
                font-size: 1em;
            }
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 33.5%;
        left: 5.4%;
        width: 100% - 10.8%;

        @media (min-width: 375px) {
            top: 37.5%;
        }

        @include media-breakpoint-up(sm) {
            top: auto;
            bottom: 13%;
            left: 4.5%;
            flex-direction: row;
            width: 62.16%;
        }

        &-column {
            width: 50%;

            &:first-child {
                order: 1;

                @include media-breakpoint-up(sm) {
                    order: 0;
                }
            }
        }

        &-data:not(:last-child) {
            margin-bottom: .4em;

            @include media-breakpoint-up(sm) {
                margin-bottom: .8em;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 1.18em;
            }
        }

        &-label {
            color: $secondary-color;
            font-size: 14px;

            @include media-breakpoint-up(sm) {
                font-size: 1em;
            }
        }

        &-value {
            margin-bottom: 10px;
            font-size: 16.47px;
            font-weight: $font-weight-light;
            line-height: 1.33;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                font-size: 1.17em;
                line-height: 1.32;
            }

            @include media-breakpoint-up(xl) {
                font-size: 1.47em;
            }

            a {
                @extend %link-underline;
                color: $primary-color;
            }
        }
    }

    &__details {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
            position: absolute;
            bottom: 13%;
            left: 69.37%;
        }

        &-data:not(:last-child) {
            margin-bottom: .4em;

            @include media-breakpoint-up(sm) {
                margin-bottom: .8em;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 1.18em;
            }
        }

        &-label {
            color: $secondary-color;
            font-size: .9em;

            @include media-breakpoint-up(sm) {
                font-size: 1em;
            }
        }

        &-value {
            font-size: .9em;
            font-weight: $font-weight-light;
            line-height: 1.32;

            @include media-breakpoint-up(sm) {
                font-size: 1.17em;
            }

            @include media-breakpoint-up(xl) {
                font-size: 1.47em;
            }
        }
    }
}
